<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    collapseHeight="60"
    :onExport="isGov?null:onExport"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <div class="filter-item">
        <el-form-item label="相册名称：" prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="请输入相册名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="数据类型：" prop="type">
          <el-select v-model="type" placeholder="请选择数据类型 ">
            <el-option
              v-for="item in TypeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类：" prop="categoryName">
          <el-select v-model="form.id" placeholder="请选择 ">
            <el-option
              v-for="item in categoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import {
  documentActivityPhotoList,
  categoryList,
} from "../../api/activity-album/category-detail";
import { getGovAlbumCategories } from "@/modules/gov/api/album-library";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      TypeArr: [
        { id: 1, name: "已有的活动数据" },
        { id: 0, name: "新增的活动数据" },
      ],
      categoryOptions:[]
    };
  },
  watch: {
    'filterForm.project_id': {
      handler() {
        this.getCategoryOptions()
      }
    }
  },
  methods: {
    // 获取分类配置选项
    getCategoryOptions() {
      if (this.isGov) {
        getGovAlbumCategories({
          project_id: this.filterForm.project_id,
        }).then(res => {
          const arr = res.data.map(el => ({
            id: el.value,
            name: el.label
          }));
          this.categoryOptions = arr
        }).catch(() => {
        })
        return
      }
      categoryList().then((res) => {
        this.categoryOptions = res.data.data;
      });
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      documentActivityPhotoList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    type: {
      get() {
        return this.form.type == -1 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  mounted() {
    this.getCategoryOptions();
  },
};
</script>
