<template>
  <el-dialog :visible="openVideo"
             class="dialog-vertical video-dialog"
             @open="play"
             append-to-body
             @close="close"
             close-on-click-modal
             close-on-press-escape
  >
<!--    <div ref="dplayer" class="video-player"></div>-->
    <video-player class="video-player vjs-custom-skin" ref="videoPlayer"
                  :playsinline="true" :options="playerOptions"></video-player>
  </el-dialog>
</template>
<script>

  // import Hls from 'hls.js';
  // import DPlayer from "dplayer";

  export default {
    name: 'VideoViewer',
    data() {
      return {
        // initDplay: null,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            // {
            //   type: "application/x-mpegURL",
            //   src: "https://hls.cntv.myhwcdn.cn/asp/hls/main/0303000a/3/default/6488577350704cf6a7925556b9e270e3/main.m3u8?maxbr=1400"
            // },
            // {
            //   type: "application/x-mpegURL",
            //   src: "https://cdn.letv-cdn.com/2018/12/05/JOCeEEUuoteFrjCg/playlist.m3u8", //视频url地址
            // },
          ],
          poster: "", //你的封面地址
// width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        }
      };
    },
    computed: {
      openVideo: function () {
        return this.$store.state.media.isOpenMediaVideoDialog;
      },
      videoData: function () {
        return this.$store.state.media.dialogDetail;
      }
    },
    watch: {
      openVideo(val) {
        if (val) {
          if (!this.videoData || !this.videoData.url) return;
          // this.initDplay(this.videoData);
          this.playerOptions.sources = [];
          let type;
          if (this.videoData.url.indexOf('.m3u8') !== -1) {
            type = "application/x-mpegURL";
          } else {
            type = "video/mp4";
          }
          this.playerOptions.sources.push({
            type,
            src: this.videoData.url
          })
          this.timer = setTimeout(() => {
            this.play()
          }, 1200)
        } else {
          // if (this.$dplay) this.$dplay.destroy();
          this.pause()
        }
      },
    },
    destroyed() {
      clearTimeout(this.timer)
    },
    methods: {
      play() {
        // if (this.$dplay) this.$dplay.play();
        try {
          this.$refs["videoPlayer"].player.play();
        } catch(e) {
          // console.log(e)
        }
      },
      pause() {
        // if (this.$dplay) this.$dplay.pause();
        try {
          this.$refs["videoPlayer"].player.pause();
        } catch(e) {
          // console.log(e)
        }
      },
      close() {
        // 设置为关闭会自动触发（watch）视频暂停
        this.$store.commit('media/setIsOpenMediaVideoDialog', false)
      },
      /** @deprecated
       * 初始化
       */
      initDplay(videoData) {
        this.$nextTick(() => {
          let hasLiveSuffix = videoData.url.indexOf('.m3u8') !== -1;
          if (hasLiveSuffix) {
            this.$dplay = new DPlayer({
              container: this.$refs.dplayer,
              screenshot: false,
              // live: true,
              // apiBackend: {
              //   read: function (endpoint, callback) {
              //     console.log('Pretend to connect WebSocket');
              //     // callback();
              //   },
              //   send: function (endpoint, danmakuData, callback) {
              //     console.log('Pretend to send danamku via WebSocket', danmakuData);
              //     // callback();
              //   },
              // },
              video: {
                url: videoData.url,
                // thumbnails: videoData.thumbnails,
                // url: "http://kbs-dokdo.gscdn.com/dokdo_300/_definst_/dokdo_300.stream/playlist.m3u8",
                // type: 'hls',
                type: 'customHls',
                customType: {
                  customHls: function (video, player) {
                    const hls = new Hls();
                    hls.on(Hls.Events.ERROR, function (eventName, data) {
                      console.log(eventName, data)
                    })
                    hls.loadSource(video.src);
                    hls.attachMedia(video);
                  },
                },
              },
            });
            console.log(this.$dplay.plugins.hls); // Hls instance
            // 监听loadedmetadata事件
            this.$dplay.on('loadedmetadata', function (e) {
              console.log('load', e)
            })
            this.$dplay.on('play', function (e) {
              console.log('play', e)
            })
          } else {
            this.$dplay = new DPlayer({
              container: this.$refs.dplayer,
              screenshot: false,
              video: {
                url: videoData.url,
                thumbnails: videoData.thumbnails,
              },
            });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .video-dialog {
    .el-dialog,
    ::v-deep .el-dialog {
      width: 715px;
      background-color: transparent;
      box-shadow: none;

      .el-dialog__header {
        .el-dialog__title {
          display: block;
          @include nowrap();
        }
      }

      .el-dialog__close {
        color: #fff;
      }

      .el-dialog__body {
        padding-top: 10px;
      }
    }

    .video-player {
      width: 650px;
      height: 400px;
      background-color: black;
      display: flex;
      align-items: center;
    }
  }
</style>
