<template>
  <el-dialog append-to-body title="选择活动" :visible.sync="openDialog" width="60%" :show-close="false" class="dialog-vertical"
    @close="cancel">
    <div class="head-filter">
      <el-input size="medium" v-model="filterForm.keyword" placeholder="请输入活动名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list">
      <el-table :data="SelectList" style="width: 100%" :height="250" ref="multipleTable"
        @selection-change="handleSelectionChange" @row-click="handleCurrentChange" v-loading="loading"
        v-loadmore="LoadMore">
        <!-- 多选 -->
        <el-table-column fixed type="selection" width="70" :selectable="selectable" :key="Math.random()">
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index">
            <template slot-scope="scope">
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  ActivityList,
  saveActivity,
} from "../../api/activity-album/category-detail";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
    selected: {
      type: Array,
    },
    update: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载
      saveLoading: false,
      thead: [
        { label: "活动名称", prop: "name", minWidth: 150 },
        { label: "活动时间", prop: "activity_time_text", minWidth: 250 },
      ],
      SelectList: [], // 活动列表
      getable: true, // 是否还可以获取列表
      //筛选对象
      filterForm: {
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取活动列表
    getActivityList() {
      if (!this.getable) return;
      this.loading = true;
      const pageData = { ...this.filterForm };
      pageData.source_id = pageData.source_id === "" ? -1 : pageData.source_id;
      ActivityList(pageData)
        .then((res) => {
          const { data } = res;
          if (pageData.page === 1) {
            this.SelectList = data;
          } else {
            this.SelectList = [...this.SelectList, ...data];
          }
          if (data.length < 10) this.getable = false;
          this.filterForm.page++;
          this.loading = false;
        })
        .catch((err) => {});
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      if (val.is_added) return;
      this.SelectList.forEach((element) => {
        if (val.id === element.id) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(element);
          });
        }
      });
    },
    // 判断是否可选
    selectable(row, index) {
      if (this.selected.length) {
        const ids = [];
        this.selected.forEach((el) => {
          ids.push(el.activity_id);
        });
        return !ids.includes(row.id);
      } else {
        return true;
      }
    },
    // 筛选操作
    handleFilter() {
      this.getable = true;
      this.filterForm.page = 1;
      this.getActivityList();
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        selection.forEach((element) => {
          this.selectArr.push(element.id);
        });
      }
    },
    //触底刷新
    LoadMore() {
      this.getActivityList();
    },
    // 确定
    submit() {
      if (!this.selectArr.length) {
        this.$message.info("请选择数据项");
      } else {
        this.saveLoading = true;
        const data = {
          activity_id: this.selectArr,
          photo_category_id: this.id,
        };
        saveActivity(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.saveLoading = false;
            this.update();
            this.clearDialog();
          })
          .catch((err) => {
            this.saveLoading = false;
          });
      }
    },
    // 清除弹窗数据
    clearDialog() {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      this.filterForm.page = 1;
      this.getable = true;
      this.getActivityList();
      this.selectArr = [];
      this.openDialog = false;
    },
    // 取消
    cancel(formName) {
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getable = true;
        this.filterForm.keyword = "";
        this.filterForm.page = 1;
        this.getActivityList();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    data() {
      return this.selectData;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
