<template>
  <base-upload-dialog
    :code="code"
    :folders="showFolders ? folderOptions : []"
    :limit-type="limitType"
    :on-complete="onComplete"
    :on-refresh-code="getCode"
    :on-upload="onUpload"
    :open="value"
    :show-mask="showMask"
    :multiple="multiple"
    @close="$emit('input', false)"
  >
    <el-scrollbar
      ref="scrollbarRef"
      style="height: 100px; margin-top: 20px"
      v-if="progressLogs"
    >
      <p
        ref="innerRef"
        style="font-size: 10px; line-height: normal; white-space: pre-wrap"
      >
        {{ progressLogs }}
      </p>
    </el-scrollbar>
  </base-upload-dialog>
</template>
<script>
import filesUpload from '../../../base/utils/upload4'
import BaseUploadDialog from '../../../base/components/Media/BaseUploadDialog'
import { scanCode } from '../api/upload'
import { mediaFolders } from '../api/media'

export default {
  components: { BaseUploadDialog },
  props: {
    // 是否显示 v-model
    value: {
      type: Boolean,
      required: true,
    },
    // 上传类型限制
    limitType: {
      type: Array,
      default: function () {
        return ['image', 'video', 'audio', 'file']
      },
    },
    // 是否关闭遮罩
    showMask: {
      type: Boolean,
      default: true,
    },
    showFolders: {
      type: Boolean,
      default: true,
    },
    // 上传完成后的回调
    onComplete: Function,
    // 是否启用加密上传
    encrypt: [Boolean, Number],
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      code: '',

      progressLogs: ``,
    }
  },
  computed: {
    folderOptions: function () {
      return this.showFolders ? this.$store.state.media.folderOptions
        ? this.$store.state.media.folderOptions.filter((item) => item.id !== -1)
        : [] : []
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.progressLogs = ''
        this._fileNames = []

        if (this.showFolders) {
          if (this.folderOptions?.length) {
          } else {
            this.getFolders()
          }
        }
      }
    },
  },
  methods: {
    getFolders() {
      mediaFolders()
        .then((res) => {
          const { data } = res
          this.folders = [
            { title: '全部', id: -1 },
            { title: '根目录', id: 0 },
          ].concat(data)
          this.$store.commit('media/setFolderOptions', this.folders)
        })
        .catch((err) => {})
    },
    getCode() {
      scanCode()
        .then((res) => {
          this.code = res.data
        })
        .catch((err) => {})
    },
    // 触发上传
    onUpload({ files, extra, callback, progress }) {
      filesUpload({
        // 上传配置获取api
        configApi: '/admin/admin/media/requestUpload',
        data: files,
        pid: extra.pid,
        encrypt: this.encrypt || 0,
        // 进度回调
        progress: (num, currentProgress) => {
          progress(num)

          // '进度：' + percent + '%; 速度：' + speed + 'Mb/s;'
          if (currentProgress) {
            if (!this._fileNames) this._fileNames = []

            if (
              this._fileNames &&
              this._fileNames.includes(currentProgress.file.name)
            ) {
              // 忽略
            } else {
              if (this._fileNames.length) this.progressLogs += `\n\n` // 与上一个文件隔开

              this._fileNames.push(currentProgress.file.name)
              this.progressLogs += `${currentProgress.file.name}上传中...\n` // 表示到了下一个文件
            }

            this.progressLogs += `进度：${currentProgress.percent}%；速度：${currentProgress.speed}Mb/s；\n`

            this.$nextTick(() => {
              if (this.$refs.scrollbarRef)
                this.$refs.scrollbarRef.wrap.scrollTop =
                  this.$refs.innerRef.clientHeight
            })
          }
        },
      })
        .then((res) => {
          callback(res)
        })
        .catch((err) => {
          console.log(err)
          callback()
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-dialog {
  .content {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .loading {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      .info {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>
