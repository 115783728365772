import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

// 分类详情列表数据
export const detailList = data => {
  return api({
    url: '/admin/cyc_activity/photo/detail',
    method: 'post',
    data
  });
};

// 获取活动列表
export const ActivityList = data => {
  return api({
    url: "/admin/cyc_activity/activity/searchActivity",
    method: "post",
    data
  });
};

// 保存选择的活动
export const saveActivity = data => {
  return api({
    url: "/admin/cyc_activity/photo/saveActivity",
    method: "post",
    data
  });
};

// 保存自定义的活动
export const saveCustomActivity = data => {
  return api({
    url: "/admin/cyc_activity/photo/saveCustom",
    method: "post",
    data
  });
};

// 保存封面图配置
export const activityCover = data => {
  return api({
    url: "/admin/cyc_activity/photo/activityCover",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc_activity/photo/activitySort",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc_activity/photo/activityIsShow",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc_activity/photo/activityDel",
    method: "post",
    data
  });
};

// 活动相册导出
export const documentActivityPhotoList = data => {
  return fetchFile({
    url: "/admin/cyc_activity/photo/detailExport",
    method: "post",
    data,
    download: true
  });
};
// 分类列表数据
export const categoryList = data => {
  return api({
    url: '/admin/cyc_activity/photo/index2',
    method: 'post',
    data
  });
};