import { api } from "@/base/utils/request";

export const getGovAlbumList = (data) => {
  return api({
    url: '/admin/gov/photo/index',
    method: 'post',
    data
  })
}

export const getGovAlbumCategories = (data) => {
  return api({
    url: '/admin/gov/photo/allCategories',
    method: 'post',
    data
  })
}
