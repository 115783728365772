<template>
  <div class="list-page">
    <Reminder text="尽量上传每条数据的封面图，以免影响在客户端的展示效果" />
    <detail-list-filter
      :filterForm="filterForm"
      :uploadFilter="ok"
      :ids="selectArr"
      :page="pageData.current_page"
    ></detail-list-filter>
    <div class="list-btn">
      <!-- <el-button type="primary" size="small" @click="openActivitySelector = true">选择活动</el-button> -->
      <el-button
        class="top-btn"
        type="primary"
        size="small"
        @click="addCustomActivity"
        >新增
      </el-button>
      <el-button type="danger" size="small" @click="handleDel"
        >批量删除</el-button
      >
    </div>
    <div class="list-content" v-loading="loading">
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" @click="editRow(scope.row)" type="text">
                编辑
              </el-button>
              <el-button size="small" @click="uploadImg(scope.row)" type="text">
                上传图片
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <!-- 显示 -->
              <el-checkbox
                v-if="th.prop === 'is_show'"
                :false-label="0"
                :true-label="1"
                v-model="scope.row[th.prop]"
                @change="changeStatus(scope.row, th.prop)"
              >
              </el-checkbox>
              <!-- 封面图 -->
              <el-image
                v-else-if="th.prop === 'cover'"
                class="list-image cursor-pointer"
                :src="fixImageUrl(scope.row[th.prop])"
                @click="$previewImage([scope.row[th.prop]])"
                fit="cover"
              ></el-image>
              <!-- 排序 -->
              <PopoverInputEdit
                v-else-if="th.prop === 'sort'"
                v-model="scope.row.sort"
                input-type="number"
                :validator="validator"
                @confirm="changeStatus(scope.row, th.prop)"
              >
              </PopoverInputEdit>
              <!-- 类型 -->
              <span v-else-if="th.prop === 'type'">{{
                scope.row.start_time ? "新增的活动数据" : "已有的活动数据"
              }}</span>
              <span v-else-if="th.prop === 'photo_image_count'">{{
                scope.row.photo_image_count + scope.row.photo_video_count
              }}</span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <ActivitySelector
      v-model="openActivitySelector"
      :id="$route.params.id"
      :selected="tbody"
      :update="refreshList"
    />
    <EditActivityDialog
      v-model="openEditActivityDialog"
      :Form="currentForm"
      :tbody="tbody"
      :openType="openType"
      :categoryId="photo_category_id"
      :updateList="refreshList"
    />
  </div>
</template>

<script>
import Reminder from "../../../common/components/Reminder";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import {
  detailList,
  updateSort,
  updateShow,
  Delete,
} from "../../api/activity-album/category-detail";
import ActivitySelector from "../../components/activity-album/ActivitySelector.vue";
import EditActivityDialog from "../../components/activity-album/EditActivityDialog.vue";
import DetailListFilter from "../../components/activity-album/DetailListFilter.vue";
export default {
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "相册名称", prop: "activity_name", minWidth: 150 },

        //   { label: "封面图", prop: "cover", minWidth: 110 },
        {
          label: "图片数量",
          prop: "photo_image_count",
          minWidth: 120,
        },
        {
          label: "数据类型",
          prop: "type_text",
          minWidth: 110,
        },
        {
          label: "分类名称",
          prop: "category_name",
          minWidth: 110,
        },
        // { label: "排序", prop: "sort" },
        { label: "显示", prop: "is_show" },
        { label: "创建时间", prop: "create_time", minWidth: 200 },
      ],
      //筛选对象
      filterForm: {
        id: "",
        page_size: 15,
        keyword: "",
        photo_category_id: "",
        type: -1,
      },
      pageData: {},
      tbody: [],
      selectArr: [], //勾选的数组id
      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      openType: "",
      currentForm: {},
      pid_list: [], //上级组织列表,
      openAddCategory: false,
      openActivitySelector: false,
      openEditActivityDialog: false,
      photo_category_id: "",
    };
  },
  methods: {
    // 获取品牌列表
    getDetailList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      detailList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击新增
    addCustomActivity() {
      this.currentForm = {
        id: 0,
        activity_id: 0,
        activity_name: "",
        cover:
          window.serverConfig.VUE_APP_ADMINURL +
          "/cyc_activity/images/admin/blessing.png",
        description: "",
        start_time: 0,
        end_time: 0,
        share_image: "",
      };
      this.openType = "add";
      this.openEditActivityDialog = true;
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      this.$msgbox
        .confirm("确定要将此数据删除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete({ id: row.id });
        })
        .catch((err) => {});
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      Delete(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      this.currentForm = row;
      this.openType = "edit";
      this.openEditActivityDialog = true;
    },
    // 点击上传图片
    uploadImg(row) {
      this.$router.push({
        name: "uploadImages",
        params: {
          id: row.id,
          activity_id: row.activity_id,
          category_id: row.photo_category_id,
          type: !(row.activity_id > 0) ? "add" : "select",
        },
      });
    },
    // 刷新列表
    refreshList() {
      this.getDetailList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      this.loading = true;
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getDetailList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getDetailList(data);
    },
  },
  created() {
    this.getDetailList(this.filterForm); //获取列表数据
  },
  components: {
    Reminder,
    Pagination,
    PopoverInputEdit,
    ActivitySelector,
    EditActivityDialog,
    DetailListFilter,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-image {
  width: 60px;
  height: 60px;
}
.list-btn {
  margin-top: 20px;
}
</style>
