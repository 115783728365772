<template>
  <el-dialog
    :title="title"
    :visible.sync="openDialog"
    :width="!editForm.activity_id ? '560px' : '400px'"
    class="dialog-vertical"
    @close="cancel('editForm')"
    @open="dialogOpen"
    :z-index="888"
  >
    <el-form
      size="medium"
      :model="editForm"
      :rules="rules"
      label-position="right"
      label-width="90px"
      ref="albumForm"
      @submit.native.prevent
    >
      <el-form-item
        label="新增类型："
        prop="activity_type"
        v-if="!editForm.activity_id && openType == 'add'"
      >
        <el-radio-group v-model="activity_type">
          <el-radio :label="0">单独新增相册</el-radio>
          <el-radio :label="1">从活动中选择(保留活动的相册数据)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="选择活动："
        prop="selectActivity_name"
        v-if="!editForm.activity_id && activity_type"
      >
        <el-button type="primary" @click="openActivitySelector = true">
          {{
            editForm.selectActivity_name ? editForm.selectActivity_name : '选择'
          }}
        </el-button>
      </el-form-item>
      <el-form-item
        label="相册名称："
        prop="activity_name"
        v-if="!editForm.activity_id && !activity_type"
      >
        <el-input
          style="width: 90%"
          type="text"
          v-model="editForm.activity_name"
          placeholder="请填写"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="选择分类：" prop="categoryId">
        <div class="flex">
          <el-select v-model="editForm.categoryId" placeholder="请选择">
            <el-option
              v-for="item in categoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- 通用添加分类组件 -->
          <AddCategoryButton
            :style="{
              marginLeft: '12px',
            }"
            :updateList="getCategoryOptions"
            :getApi="getApi"
          >
          </AddCategoryButton>
        </div>
      </el-form-item>

      <el-form-item label="封面图：" prop="cover">
        <DefaultImageSelector
          v-model="editForm.cover"
          :DefaultImage="defaultCover"
          :width="214"
          :height="119"
          ratio="5:4"
          :show-cut="true"
        >
          <p slot="info" class="img-tips">建议尺寸为1500*1200</p>
        </DefaultImageSelector>
        <el-input v-show="false" v-model="editForm.cover"></el-input>
      </el-form-item>
      <!-- <el-form-item label="活动时间：" prop="activity_time" v-if="!editForm.activity_id">
        <el-date-picker style="width: 90%" v-model="editForm.activity_time" type="datetimerange"
          range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="timestamp">
        </el-date-picker>
      </el-form-item> -->
      <el-form-item label="开始时间：" prop="start_time">
        <el-date-picker
          v-model="editForm.start_time"
          :picker-options="startPickerOptions"
          type="datetime"
          placeholder="选择开始时间"
          time-arrow-control
          :disabled="!!activity_type || !!editForm.activity_id"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间：" prop="end_time">
        <el-date-picker
          v-model="editForm.end_time"
          :picker-options="endPickerOptions"
          type="datetime"
          placeholder="选择结束时间"
          time-arrow-control
          :disabled="!!activity_type || !!editForm.activity_id"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="简介："
        prop="description"
        v-if="!editForm.activity_id && !activity_type"
      >
        <el-input
          style="width: 90%"
          type="textarea"
          :maxlength="500"
          :rows="6"
          show-word-limit
          v-model="editForm.description"
          placeholder="请填写简介"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        style="margin-bottom: 0px"
        label="微信分享图："
        prop="share_image"
      >
        <SingleMediaWall
          v-model="editForm.share_image"
          :width="214"
          :height="119"
          ratio="5:4"
          :show-cut="true"
        >
          <p slot="info" class="img-tips">建议尺寸为1500*1200</p>
        </SingleMediaWall>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submit('albumForm')"
        :loading="saveLoading"
        >保存</el-button
      >
    </span>
    <ActivitySelector
      v-model="openActivitySelector"
      @selectActivity="selectActivity"
      :selected="tbody"
      :update="updateList"
    />
  </el-dialog>
</template>

<script>
import {
  saveCustomActivity,
  activityCover,
  saveActivity,
  categoryList,
} from '../../api/activity-album/category-detail'
import { saveCategory } from '../../api/activity-album/category-list'
import DefaultImageSelector from '@/modules/common/components/DefaultImageSelector.vue'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import ActivitySelector from './ActivitySelector2.vue'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'
export default {
  components: {
    SingleMediaWall,
    DefaultImageSelector,
    ActivitySelector,
    AddCategoryButton,
  },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    openType: {
      type: String,
      default: 'add',
    },
    tbody: {
      type: Array,
    },
    Form: {
      type: Object,
      default: () => {},
    },

    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      title: '',
      saveLoading: false,
      activity_type: 0,
      editForm: {
        id: '',
        activity_name: '',
        selectActivity_name: '',
        cover:
          window.serverConfig.VUE_APP_ADMINURL +
          '/cyc_activity/images/admin/blessing.png',
        description: '',
        start_time: '',
        end_time: '',
        share_image: '',

        // 要添加的相册分类id
        categoryId: '',
      },
      // 选择分类配置
      categoryOptions: [],
      // 打开活动选择
      openActivitySelector: false,
      rules: {
        activity_name: [
          { required: true, message: '请填写活动名称', trigger: 'change' },
        ],
        categoryId: [
          { required: true, message: '请选择分类', trigger: 'blur' },
        ],
        cover: [{ required: true, message: '请上传封面图', trigger: 'change' }],
        selectActivity_name: [
          {
            required: true,
            message: '请选择活动',
            trigger: 'blur',
          },
        ],
        description: [
          { required: false, message: '请填写活动简介', trigger: 'blur' },
        ],
      },

      // 活动开始时间选择范围
      startPickerOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time, 0)
        },
      },
      // 活动结束时间选择范围
      endPickerOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time, 1)
        },
      },
      defaultCover:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc_activity/images/admin/blessing.png',
    }
  },
  watch: {
    openType(val) {
      switch (val) {
        case 'add':
          this.title = '新增'
          break
        case 'edit':
          this.title = '编辑'
          break
      }
    },
  },
  methods: {
    // 添加分类通用
    getApi(data) {
      return saveCategory(data)
    },
    selectActivity(selectItem) {
      console.log(selectItem)
      this.selectItem = selectItem
      this.editForm.start_time = selectItem.activity_start_time * 1000
      this.editForm.end_time = selectItem.activity_end_time * 1000
      this.editForm.cover = selectItem.cover
      this.editForm.selectActivity_name = selectItem.name
      this.editForm.id = selectItem.id
      console.log(this.editForm)
    },
    dialogOpen() {
      this.editForm = { ...this.editForm, ...this.Form }
      console.log(this.editForm)
      console.log(this.Form)
      if (this.Form.start_time) {
        this.editForm.start_time = this.Form.start_time * 1000
      } else {
        this.editForm.start_time = ''
      }
      if (this.Form.end_time) {
        this.editForm.end_time = this.Form.end_time * 1000
      } else {
        this.editForm.end_time = ''
      }
      this.editForm.categoryId = this.Form.photo_category_id
      this.getCategoryOptions()
    },
    // 设置开始时间选择范围
    selectableDate(time, type) {
      if (type) {
        return this.editForm.start_time
          ? time.getTime() < this.editForm.start_time - 86400000
          : false
      } else {
        return this.editForm.end_time
          ? time.getTime() > this.editForm.end_time - 86400000
          : false
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 单独新增
          if (this.activity_type === 0) {
            this.saveLoading = true
            // this.$emit("addFeeConfig",this.form)
            if (!this.editForm.activity_id) {
              const data = { ...this.editForm }
              data.start_time = data.start_time ? data.start_time / 1000 : 0
              data.end_time = data.end_time ? data.end_time / 1000 : 0
              data.photo_category_id = data.categoryId
              saveCustomActivity(data)
                .then((res) => {
                  this.$message.success(res.msg)
                  this.updateList()
                  this.openDialog = false
                  this.saveLoading = false
                })
                .catch((err) => {
                  this.saveLoading = false
                })
            } else {
              const data = {
                id: this.editForm.id,
                cover: this.editForm.cover,
                share_image: this.editForm.share_image,
              }
              activityCover(data)
                .then((res) => {
                  this.$message.success(res.msg)
                  this.updateList()
                  this.openDialog = false
                  this.saveLoading = false
                })
                .catch((err) => {
                  this.saveLoading = false
                })
            }
          } else if (this.activity_type === 1) {
            const data = {}

            data.photo_category_id = this.editForm.categoryId
            data.activity_id = [this.editForm.id]
            data.cover = this.editForm.cover
            data.share_image = this.editForm.share_image
            data.start_time = this.editForm.start_time
              ? this.editForm.start_time / 1000
              : 0
            data.end_time = this.editForm.end_time
              ? this.editForm.end_time / 1000
              : 0
            console.log(data)
            saveActivity(data)
              .then((res) => {
                this.$message.success(res.msg)
                this.saveLoading = false
                this.cancel()
                this.openDialog = false
                this.$props.updateList()
              })
              .catch((err) => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    // 获取分类配置选项
    getCategoryOptions() {
      categoryList().then((res) => {
        this.categoryOptions = res.data.data
      })
    },
    // 取消
    cancel() {
      this.activity_type = 0
      this.editForm = {
        id: '',
        activity_name: '',
        selectActivity_name: '',
        cover:
          window.serverConfig.VUE_APP_ADMINURL +
          '/cyc_activity/images/admin/blessing.png',
        description: '',
        start_time: '',
        end_time: '',
        share_image: '',

        // 要添加的相册分类id
        categoryId: '',
      }
      this.$refs['albumForm'].resetFields()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}

.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
